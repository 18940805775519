<template>
  <div  v-if="invoice">
    <component-layout :title="'Payment'">
      <template #menu v-if="invoice.pay_status != 'Paid'">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list >
            <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ name: 'invoice.payment.add', params: { id: invoice.id } }"
            >
              <v-list-item v-ripple="{ center: true }">
                ADD
              </v-list-item></router-link
            >
          </v-list>
        </v-menu>
      </template>
      <v-row>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="deep-purple accent-4"
          class="mb-1"
        ></v-progress-linear>
      </v-row>
      <div class="row py-10">
        <div class="col-md-3">
          <div>
            Invoice No:
            <div>
              <h4>{{ invoice.ref_no }}</h4>
              <pay-status :val="invoice.paid_status"></pay-status>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            Total Invoice Amount:
            <div>
              <h4>{{invoice.total_formatted}}</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            Total Paid Amount:
            <div>
              <h4 class="text-success">{{ invoice.paid_amount_formatted }}</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            Total Due Amount:
            <div>
              <h4 class="text-danger">{{invoice.due_amount_formatted}}</h4>
            </div>
          </div>

        </div>
      </div>

      <div>
        <table class="table" style="width: 100%">
          <thead>
            <tr class="primary-bg ">
              <th scope="col" class="text-start py-3 px-2">Paid Amount</th>
              <th scope="col" class="text-start py-3 px-2">Paid Date</th>
              <th scope="col" class="text-start py-3 px-2">Payment Method</th>
              <th scope="col" class="text-start py-3 px-2">Paid By</th>
              <th scope="col" class="text-start py-3 px-2">Received By</th>
              <th scope="col" class="text-start py-3 px-2">Remarks</th>
              <th scope="col" class="text-start py-3 px-2">Attachments</th>
              <th scope="col" class="text-start py-3 px-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="invoice.payments.length > 0">
              <tr v-for="(item) in invoice.payments" :key="item.id">
                <td class="text-start py-3 px-2">{{ item.paid_amount_formatted }}</td>
                <td class="text-start py-3 px-2">{{ item.paid_date_formatted }}</td>
                <td class="text-start py-3 px-2">{{ item.payment_method }}</td>
                <td class="text-start py-3 px-2">{{ item.paid_by }}</td>
                <td class="text-start py-3 px-2">{{ item.recipient }}</td>
                <td class="text-start py-3 px-2">{{ item.remarks }}</td>
                <td class="text-start py-3 px-2">{{item.file}}</td>
                <td class="text-start py-3 px-2">
                  <span>
                    <v-icon @click="editProduct(item.id)" small title="Edit" color="#ff9a02">
                      mdi-pencil
                    </v-icon>
                  </span>
                  |
                  <span>
                    <v-icon @click="deleteObject" small title="Delete" color="#d00101">
                      mdi-delete
                    </v-icon>
                  </span>
                  <slot></slot>
                  <v-dialog v-model="showModal" max-width="500px">
                    <deleteDialog
                      resource="payment"
                      :id="item.id"
                      @cancel="cancel"
                      @reload-resource="reloadResource"
                    />
                  </v-dialog>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="8" class="text-center">
                  <h4>No Payments</h4>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout.vue";
import deleteDialog from "@/components/shared/delete-modal";
import payStatus from "@/components/Invoice/partials/pay-status.vue";
export default {
  components: { componentLayout, deleteDialog, payStatus },
  data() {
    return {
      loading: false,
      invoice: null,
      showModal: false
    };
  },
  mounted() {
    this.getPaymentList();
  },
  methods: {
    getPaymentList() {
      this.loading = true;
      util
        .http({ url: "/payment/list/" + this.$route.params.id, method: "get" })
        .then((res) => {
          this.invoice = res.data.data;
          this.loading = false;
        });
    },
    editProduct(id) {
      this.$router.push({
        name: 'invoice.payment.edit',
        params: { id: id }
      })
    },
    deleteObject() {
      this.showModal = true;
    },
    cancel() {
      this.showModal = false;
    },
    reloadResource() {
      this.cancel();
      this.getPaymentList();
    },
    getColor(val) {
      return util.getColor(val);
    }
  },
};
</script>
